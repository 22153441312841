import React from 'react'

export function ErrorFallback({ error, resetErrorBoundary }: ErrorMessageProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button
        onClick={resetErrorBoundary}
        // onClick={() => {
        //   // this next line is why the fallbackRender is useful
        //   //resetComponentState()
        //   // though you could accomplish this with a combination
        //   // of the FallbackCallback and onReset props as well.
        //   resetErrorBoundary()
        // }}>
      >
        Try again
      </button>
    </div>
  )
}

type ErrorMessageProps = {
  error: any
  resetErrorBoundary: (...args: Array<unknown>) => void
}

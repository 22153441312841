import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'src/components/atoms/error'
import { Layout } from 'src/components/organisms/layouts'
import { ProfileFormEdit, SignInWithMagicLinkForm } from 'src/components/organisms/signIn'
import { useAuth } from 'src/hooks'

export default function MyProfile() {
  const { user } = useAuth()

  return (
    <Layout>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, componentStack) => {
          // we could to something more clever logToErrorLoggingService
          console.log(error, componentStack)
        }}>
        {user ? <ProfileFormEdit /> : <SignInWithMagicLinkForm />}
      </ErrorBoundary>
    </Layout>
  )
}

import { Box } from '@mui/material'
import React from 'react'

export default function ErrorMessage({ error }: ErrorMessageProps) {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'red',
        fontSize: '18px'
      }}>
      {error}
    </Box>
  )
}

type ErrorMessageProps = {
  error: any
}
